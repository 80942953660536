import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";

import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrA7utF_wI2w79BfRbsWdFw_-3r2UO020",
  authDomain: "musicmapper-4d986.firebaseapp.com",
  projectId: "musicmapper-4d986",
  storageBucket: "musicmapper-4d986.appspot.com",
  messagingSenderId: "313692886243",
  appId: "1:313692886243:web:65a91683a794782fc621d6",
  measurementId: "G-P50CFBS7CR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

window.__loki_incremental_idb_debug = true;
import loki from 'lokijs';
import IncrementalIndexedDBAdapter from 'lokijs/src/incremental-indexeddb-adapter';


function defaultLoader({ params }) {
  const thisYear = '' + new Date().getFullYear();
  return { tabName: thisYear, mapName: thisYear };
}

function yearLoader({ params }) {
  console.log('yearLoader', params);
  const year = '' + params.year;
  return { tabName: year, mapName: year };
}

function playlistsLoader({ params }) {
  return { tabName: 'playlists', mapName: 'playlists' };
}

function playlistLoader({ params }) {
  const playlist = '' + params.playlist;
  return { tabName: 'playlists', mapName: playlist };
}

function bundleLoader({ params }) {
  const bundle = 'bundle-' + params.bundle;
  return { tabName: 'playlists', mapName: bundle };
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />} loader={defaultLoader} />
      <Route path="/year/:year" element={<App />} loader={yearLoader} />
      <Route path="/playlists" element={<App />} loader={playlistsLoader} />
      <Route path="/playlists/:playlist" element={<App />} loader={playlistLoader} />
      <Route path="/bundles/:bundle" element={<App />} loader={bundleLoader} />
    </>
  )
);

function initDb(result) {
  window.db2.addCollection('tracks', {
    indices: ['id', 'trackSavedYear', 'addedAt', 'hasFeatures'],
    unique: ['id'],
  });
  console.log("addCollection ALBUMS");
  window.db2.addCollection('albums', {
    indices: ['id', 'albumSavedYear', 'addedAt'],
    unique: ['id'],
  });
  console.log("addCollection PLAYLISTS");
  window.db2.addCollection('playlists', {
    indices: ['id'],
    unique: ['id'],
  });
  console.log("addCollection MAPS");
  window.db2.addCollection('maps', {
    indices: ['mapName', 'mapTrackId'],
    unique: ['mapTrackId'],
  });
  window.db2.getCollection('maps')
    .chain()
    .where((m) => typeof m.mapName === 'number')
    .update(o => o.mapName = '' + o.mapName);

  console.log('initDb done', result);
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />;
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

window.db2 = new loki('musicmapper', {
  adapter: new IncrementalIndexedDBAdapter(),
  autoload: true,
  autoloadCallback: initDb,
  autosave: true,
  autosaveInterval: 1000,
});
