import React from 'react';
import Cookies from 'js-cookie';
import TrackLoader from './TrackLoader.js'
import CompactAlbumLoader from './CompactAlbumLoader.js'
import PlaylistLoader from './PlaylistLoader.js'
import { loadFeatures, setSpotifyApi } from './features.js'
import { trackData } from './tracks.js'
import { loginUrl } from './auth.js'
import { Link } from 'react-router-dom';

var SpotifyWebApi = require('spotify-web-api-js');


function Loader(props) {
  const [albumsOffset,                   setAlbumsOffset] = React.useState(0);
  const [playlistsOffset,             setPlaylistsOffset] = React.useState(0);
  const [savedTracksOffset,         setSavedTracksOffset] = React.useState(0);

  const spotifyApi = React.useMemo(
    () => {
      const swa = new SpotifyWebApi();
      swa.setAccessToken(props.token);
      setSpotifyApi(swa);
      return swa;
    },
    [props.token]
  );

  React.useEffect(() => {
    if (savedTracksOffset > 0 || db2.getCollection('tracks')?.data.length == 0) {
      loadSavedTracks();
    }
  }, [savedTracksOffset]);

  React.useEffect(() => {
    if (albumsOffset > 0 || db2.getCollection('albums')?.data.length == 0) {
      loadAlbums();
    }
  }, [albumsOffset]);

  React.useEffect(() => {
    if (playlistsOffset > 0 || db2.getCollection('playlists')?.data.length == 0) {
      loadPlaylists();
    }
  }, [playlistsOffset]);

  React.useEffect(() => {
    // check if our token is still valid
    if (props.token) {
      spotifyApi.getMe({}, (err, data) => {
        if (err) {
          if (err.status == 401) {
            props.clearToken();
          } else {
            console.error(err);
          }
        }
      });
    }
  }, [props.token]);

  window.spotifyApi = spotifyApi;

  React.useEffect(() => {
    async function autoLoad() {
      if (!props.loading && props.token) {
        if (playlistsOffset   == 0 && (db2.getCollection('playlists')?.data.length == 0)) loadPlaylists();
        if (albumsOffset      == 0 && (db2.getCollection('albums')?.data.length == 0)) loadAlbums();
        if (savedTracksOffset == 0 && (db2.getCollection('tracks')?.data.length == 0)) loadSavedTracks();
      }
    }
    autoLoad();
  });

  async function load(apiFn, limit, offset, setOffset, collection, itemsFn, defaults, stopCondition) {
    props.setLoading(true);
    apiFn({offset: offset, limit: limit, market: 'DE'}).then(async data => {
      const items = await itemsFn(data);
      try {
        items.forEach((item, i) => {
          let itemToStore = {...{index: offset + i}, ...item, ...defaults};
          // console.log('itemToStore', itemToStore);
          collection.removeWhere({'id': item.id});
          collection.insert(itemToStore);
        });
      } catch (e) {
        console.error(e.message);
      }
      const shouldStop = items.length > 0
        && !!stopCondition
        && stopCondition(items[items.length -1]);
      if (shouldStop || data.next == null) {
        setOffset(0);
        props.setLoading(false);
      } else {
        setOffset(offset + limit);
      }
    }, err => {
      setOffset(0);
      props.setLoading(false);
      if (err.status == 401) {
        props.clearToken();
      } else {
        console.error(err);
      }
    });
  }

  async function importAlbumTracks(a) {
    // console.log("importAlbumTracks", a);
    let tracks = a.album.tracks.items.
      filter(track => !track.is_local).
      map(track => trackData(a, track, a.added_at));

    try {
      // console.log('loadFeatures', a.album.name);
      await loadFeatures(a.album.name, tracks);
      // tracks.forEach(t => db2.getCollection('tracks').insert(t));
      a.tracksWithFeatures = tracks.filter(t => t.hasFeatures == 1);
      a.trackIds = tracks.map(t => t.id);
      a.trackCount = tracks.length;
    } catch (e) {
      console.error(e.message);
    }
  }

  function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  async function importPlaylistTracks(p) {
    console.log("importing tracks of playlist", p.id, "offset", p.offset, p.name);
    return new Promise((resolve, reject) => {
      function handlePage() {
        spotifyApi.getPlaylistTracks(p.id, {offset: p.offset, limit: 100, market: 'DE'}, async (err, data) => {
          if (err) {
            console.error(err);
            p.offset = 0; // reset to initial so it gets picked up again
            reject(err);
          }
          else if (data) {
            const tracks = data.items
              .filter(item => item.track && !item.track.is_local && item.track.preview_url)
              .map(item => {
                let t = trackData(item.track, item.track, item.added_at);
                t.playlistIds = [p.id];
                // t['selected'] = 1; // don't auto-select them if they're new
                return t;
              });
            // console.log('tracks', tracks);
            // console.log('tracksWithFeatures', p.tracksWithFeatures);
            // TODO optimization: existing tracks may have been loaded via albums or other playlists
            try {
              await loadFeatures(p.name, tracks);
              if (!isIterable(p.tracksWithFeatures)) p.tracksWithFeatures = [];
              p.tracksWithFeatures = Array.from(new Set([...p.tracksWithFeatures, ...tracks.filter(t => t.hasFeatures == 1)]));
              // console.log('tracksWithFeatures', p.tracksWithFeatures);
              p.trackIds = p.tracksWithFeatures.map(t => t.id);
              console.log('next', data.next);
              if (data.next == null) {
                p.trackCount = p.trackIds.length;
                p.offset = null;
                resolve(p);
              } else {
                p.offset += 100;
                handlePage();
              }
            } catch (e) {
              console.error(e.message, e);
              reject(e);
            }
          }
        });
      }

      handlePage();
    });
  }
  window.importPlaylistTracks = importPlaylistTracks;

  function loadAlbums(year) {
    let getItems = async data => {
      return (await Promise.all(data.items.map(async a => {
        if (!db2.getCollection('albums').findOne({id: a.album.id})) {
          // TODO compare snapshot id?
          a.id = a.album.id;
          a.albumSavedYear = new Date(a.added_at).getFullYear();
          a.trackCount = a.album.tracks.total;
          await importAlbumTracks(a);
          return a;
        } else {
          // console.log('existing album', a.album.id);
          return null;
        }
      }))).filter(a => !!a);
    };
    console.log('loadAlbums', albumsOffset);
    const stopCondition = year ? (item) => {
      return new Date(item.added_at).getFullYear() > year;
    } : null;
    load(
      spotifyApi.getMySavedAlbums, 50, albumsOffset, setAlbumsOffset,
      db2.getCollection('albums'), getItems,
      {selected: 0},
      stopCondition);
  }

  function loadPlaylists() {
    let getItems = async data => {
      let results = [];
      for (const p of data.items) { // .slice(0, 10)
        console.log(p);
        if (p.offset === undefined) p.offset = 0;
        p.savedYear = new Date(p.added_at).getFullYear();
        p.trackCount = p.tracks.total;
        await importPlaylistTracks(p);
        results.push(p);
      }
      return results;
    };
    console.log("loadPlaylists", playlistsOffset);
    load(
      spotifyApi.getUserPlaylists, 50, playlistsOffset, setPlaylistsOffset,
      db2.getCollection('playlists'), getItems,
      {selected: 0, offset: 0}); //, tracksWithFeatures: new Set()});
  }

  function loadSavedTracks() {
    let getItems = async data => {
      let tracks = data.items
        .filter(item => !item.track.is_local)
        .map(item => {
          let t = trackData(item.track, item.track, item.added_at);
          t.image = item.track.album.images[2];
          t.trackSavedYear = new Date(t.added_at).getFullYear();
          return t;
        });
      await loadFeatures('saved', tracks);
      // addToMap(t.trackSavedYear, tracksWithFeatures);
      return tracks;
    };
    console.log("loadSavedTracks", savedTracksOffset);
    load(
      spotifyApi.getMySavedTracks, 50, savedTracksOffset, setSavedTracksOffset,
      db2.getCollection('tracks'),
      getItems, {saved: 1});
  }

  const [years, setYears] = React.useState([]);

  React.useEffect(() => {
    const fromTracks = db2.getCollection('tracks').mapReduce(t => t.trackSavedYear, ys => Array.from(new Set([...ys])));
    const fromAlbums = db2.getCollection('albums').mapReduce(a => a.albumSavedYear, ys => Array.from(new Set([...ys])))
    setYears(Array.from(new Set([...fromTracks, ...fromAlbums])).sort().reverse());
  }, [albumsOffset, savedTracksOffset]);

  const [gridView, setGridView] = React.useState(true);

  let className = 'loader' + ((props.activeTab !== 'playlists' && gridView) ? ' narrow' : ' wide');

  // console.log("RENDER Loader", props.mapName);

  return (
    <div className="row">
      <div className="vertBar">
        <ul className="tabs">
          {years?.map(year => (
            <li key={year}>
              <Link className={'tab' + (props.mapName == year ? ' selected' : '')}
                to={'/year/' + year}>
                {year}
              </Link>
            </li>
          ))}
          <li>
            <Link className={'tab' + (props.mapName == 'all' ? ' selected' : '')}
              to={'/year/all'}>
              ALL
            </Link>
          </li>
          <li>
            <Link className={'tab' + (props.mapName == 'playlists' ? ' selected' : '')}
              to={'/playlists'}>
              play<br/>lists
            </Link>
          </li>
        </ul>
      </div>
      <div className={className}>
        {props.activeTab !== 'playlists' ? (
          <>
            <TrackLoader
              savedTracksOffset={savedTracksOffset}
              mapName={props.mapName}
              playTrack={props.playTrack}
              setPlayTrack={props.setPlayTrack}
              play={props.play}
              search={props.search}
              gridView={gridView} setGridView={setGridView}
              loadSavedTracks={loadSavedTracks}
              token={props.token}
            />
            <CompactAlbumLoader
              albumsOffset={albumsOffset}
              mapName={props.mapName}
              playTrack={props.playTrack}
              play={props.play}
              search={props.search}
              gridView={gridView} setGridView={setGridView}
              token={props.token}
              loadAlbums={loadAlbums}
              setHoveredAlbum={props.setHoveredAlbum}
            />
          </>
        ) : (
          <PlaylistLoader
            playlistsOffset={playlistsOffset}
            selectedPlaylist={props.selectedPlaylist}
            setSelectedPlaylist={props.setSelectedPlaylist}
            playTrack={props.playTrack}
            setPlayTrack={props.setPlayTrack}
            loadPlaylists={loadPlaylists}
            token={props.token}
            clearToken={props.clearToken}
            search={props.search}
            pushBatch={props.pushBatch}
            popBatch={props.popBatch}
            setHoveredPlaylist={props.setHoveredPlaylist}
          />
        )}
      </div>
    </div>
);
}

export default Loader;
