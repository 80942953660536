import React from 'react';


const AudioPreviewPlayer = (props) => {
  const [ready, setReady] = React.useState(true);
  const [playingTrack, setPlayingTrack] = React.useState(null);

  React.useEffect(() => {
    if (!props.quick && ready) {
      if (props.playTrack && (props.playTrack !== playingTrack)) {
        playPreview(props.playTrack);
      } else if ((props.playTrack == null) && (playingTrack !== null)) {
        pausePreview();
      }
    }
  }, [props.playTrack, playingTrack, ready]);

  function playPreview(track) {
    if (!props.quick && track.preview_url && document.getElementById('preview-source'))
    {
      setReady(false);
      document.getElementById('preview-source').setAttribute('src', track.preview_url)
      var player = document.getElementById('preview-player');
      player.load();
      player.volume = 0.25;
      var p = player.play();
      if (p !== undefined) {
        p.then(_ => {
          setPlayingTrack(track);
          setReady(true);
          if (!props.playerActive) props.setPlayerActive(true);
        })
        .catch(error => {
          setPlayingTrack(null);
          setReady(true);
        });
      }
    }
  }

  function pausePreview() {
    setPlayingTrack(null);
    document.getElementById('preview-player').pause();
  }

  React.useEffect(() => {
    if (props.pendingMapUpdate) {
      pausePreview();
    }
  }, [props.pendingMapUpdate]);

  return (
    <video id='preview-player' controls height={30} width={400}>
      <source id='preview-source' src="" type="audio/mpeg" />
    </video>
  )
}

export default AudioPreviewPlayer;
