import * as d3 from "d3";
import { updateDotXY } from './calcMap.js'
import { finishedCounter } from './calcMap.js'

var lastRunCounter = -1;

export function makeNonOverlapping(tracks, labels) {
  console.log('makeNonOverlapping', tracks.length, labels.length);
  makeDotsNonOverlapping(tracks) && makeLabelsNonOverlapping(labels, 0.7);
}
export function makeDotsNonOverlapping(tracks) {
  if (!tracks || tracks.length == 0) return false;
  // console.log('lastRunCounter', lastRunCounter, 'now', finishedCounter);
  // if (finishedCounter <= lastRunCounter) return false;
  // console.log("makeDotsNonOverlapping: doing it", finishedCounter);
  d3.forceSimulation(tracks)
    .force('collide', d3.forceCollide().radius(t => t.dotSize))
    .on('tick', () => {
      tracks.forEach(t => {
        updateDotXY(t.id, t.x, t.y, t.dotSize);
      });
    });
  lastRunCounter = finishedCounter;
  return true;
}

export function makeLabelsNonOverlapping(labels, radius) {
  if (!labels || labels.length == 0) return;
  // console.log("makeLabelsNonOverlapping");
  d3.forceSimulation(labels)
    .force('collide2', d3.forceCollide().radius(radius))
    .on('tick', () => {
      labels.forEach(l => {
        let label = document.getElementById('artist-' + l.artist);
        if (label && (label.x !== l.x || label.y !== l.y)) {
          label.setAttribute('x', l.x);
          label.setAttribute('y', l.y);
        }
      });
    });
}
