import React from 'react';
import { loginUrl } from './auth.js'
import { groupBy } from './calc.js'

import imgGrid from './img/noun-grid-18968-FFFFFF.svg'
import imgRows from './img/noun-list-18949-FFFFFF.svg'
// import imgFineGrid from './img/noun-grid-891535-FFFFFF.svg'
import imgCheckbox from './img/noun-checkbox-1142751-FFFFFF.svg'
import imgX from './img/noun-x-2172392-FFFFFF.svg'
import imgReload from './img/noun-reload-1015725-FFFFFF.svg'

const CompactAlbumLoader = (props) => {
  const [albums, setAlbums] = React.useState([]);

  React.useEffect(() => {
    if (props.mapName) {
      if (parseInt(props.mapName)) {
        let year = parseInt(props.mapName);
        setAlbums(db2.getCollection('albums').chain()
          .find({albumSavedYear: year})
          .simplesort('added_at')
          .data()
          .reverse());
      } else if (props.mapName == 'all') {
        setAlbums(
          db2.getCollection('albums').chain()
            .simplesort('added_at')
            .data()
            .reverse());
      } else {
        setAlbums([]);
      }
    }
  }, [props.mapName, props.albumsOffset]);

  function playAlbum(album, event) {
    console.log(album);
    props.play(null, {context_uri: album.album.uri});
  }

  function artists(a) {
    return a.album.artists.map(artist => artist.name).join(', ')
  }

  function hover(a) {
    props.setHoveredAlbum(a);
  }

  function leave(a) {
    props.setHoveredAlbum(null);
  }

  const search = props.search.toLowerCase();
  const playingId = props.playTrack && props.playTrack.albumId
    ? props.playTrack.albumId : null;


  function isLoaded(a) {
    return a.tracksWithFeatures?.length >= a.trackCount;
  }

  function loadedTrackCount(a) {
    return isLoaded(a)
      ? a.tracksWithFeatures.length
      : a.tracksWithFeatures
        ? "" + a.tracksWithFeatures.length + "/" + a.trackCount
        : '';
  }

  function mapTrackId(t) {
    return '' + props.mapName + '-' + t.id;
  }

  function isOnMap(t) {
    return db2.getCollection('maps').findOne({
      mapTrackId: mapTrackId(t)
    });
  }

  function isAlbumOnMap(a) {
    return a.tracksWithFeatures?.every(isOnMap);
  }

  function albumClassName(a) {
    let result = [];
    if (props.search.length > 2 && !matchSearch(a)) result.push("nomatch");
    if (isAlbumOnMap(a)) result.push("selected");
    if (playingId == a.album.id) result.push("playing");
    if (props.token && a.offset == null && a.tracksWithFeatures?.length < a.trackCount)
      result.push("loading");
    return result.join(' ');
  }

  function matchSearch(a) {
    if (a.album.name.toLowerCase().includes(search)) return true;
    if (artists(a).toLowerCase().includes(search)) return true;
    if (a.album.tracks.items.map(t => t.name).join(',').toLowerCase().includes(search)) return true;
    return false;
  }

  function combinedTrackCount(as) {
    return as.reduce((acc, a) => {
      let x = a.tracksWithFeatures?.length || 0;
      let y = a.trackCount;
      return [acc[0] + x, acc[1] + y];
    }, [0, 0]);
  }

  const [showOnlySelected, setShowOnlySelected] = React.useState(false);
  function toggleOnlySelected() {
    setShowOnlySelected(!showOnlySelected);
  }
  let className = 'albums' +
    (props.gridView ? ' grid' : ' rows') +
    (showOnlySelected ? ' showOnlySelected' : '');

  return (
    <div className={className}>
      <h3>Albums<span>by date added</span></h3>
      <div className="icons">
        <div className="left">
          {/*<img className="icon" src={imgFineGrid} onClick={() => props.setGridView(true)} />*/}
          <img className="icon grid" src={imgGrid} onClick={() => props.setGridView(true)} />
          <img className="icon rows" src={imgRows} onClick={() => props.setGridView(false)} />
        </div>
        <div className="right">
          <img className="icon showOnlySelected" src={imgCheckbox} onClick={() => toggleOnlySelected()} />
          <img className="icon reload" src={imgReload} onClick={() => props.loadAlbums(props.mapName)} />
          <img className="icon deselectAll" src={imgX} onClick={() => deselectAll()} />
        </div>
      </div>
      {albums?.length > 0 ? props.gridView ? (
        <div className="scroller">
          {albums?.map(a => (
            <img key={a.id}
              onClick={e => playAlbum(a, e)}
              className={albumClassName(a)}
              onMouseEnter={e => hover(a)}
              onMouseLeave={e => leave(a)}
              title={a.album.name + ' — ' + artists(a)}
              src={a.album.images[2].url} height={48} width={48}
            />
          ))}
        </div>
      ) : (
        <ul>
        {albums?.map(a => (
          <li key={"album-"+a.album.id}
            className={albumClassName(a)}>
            <a onClick={e => playAlbum(a, e)}>
              <img src={a.album.images[2].url} height={32} width={32} />
                <span className="title">
                  {a.album.name}<br/>{artists(a)}
                </span>
                <span className="counts">
                  {a.trackCount}
                </span>
            </a>
          </li>
        ))}
        </ul>
      ) : (
        <div className="info">
          No albums loaded yet.<br />
          {!props.token ? (
            <><a href={loginUrl}>Login to Spotify</a> to start.</>
          ) : (
            <a onClick={() => props.loadAlbums(props.mapName)}>Import from Spotify</a>
          )}
        </div>
      )}
    </div>
  );
};

export default CompactAlbumLoader;
