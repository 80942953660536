export const colorizers = {
	'danceability': (t) => t.danceability,
	'valence': (t) => t.valence,
	'instrumentalness': (t) => t.instrumentalness,
	'acousticness': (t) => t.acousticness,
	'liveness': (t) => t.liveness,
	'energy': (t) => t.energy,
	'tempo': (t) => t.normalized_tempo,
	'speechiness': (t) => t.speechiness,
}
