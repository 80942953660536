import { create, all } from 'mathjs'

const math = create(all);


export function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export function calcArtistLabels(tracks) {
	let result = [];
	// console.log('calcArtistLabels', tracks);
	let byArtist = groupBy(tracks, 'artist');
	for (const artist in byArtist) {
		if (artist !== 'Various Artists') {
			let tracks = byArtist[artist].filter(t => t.x !== null && t.y !== null);
			if (tracks.length > 0) {
				let xm = math.median(tracks.map(t => t.x));
				let ym = math.median(tracks.map(t => t.y));
				tracks = tracks.filter(t => Math.abs(t.x-xm) < 1 &&  Math.abs(t.y-ym) < 1);
				if (tracks.length > 3) {
					let xs = tracks.map(t => t.x);
					let ys = tracks.map(t => t.y);
					xm = math.median(xs);
					ym = math.median(ys);
					result.push({'artist': artist, x: xm, y: ym});
				}
			}
		}
	}
	// console.log('artists', result);
	return result;
}
