import React from 'react';
import { loginUrl } from './auth.js'

import imgReload from './img/noun-reload-1015725-FFFFFF.svg'
import imgX from './img/noun-x-2172392-FFFFFF.svg'


const TrackLoader = (props) => {
  const [tracks, setTracks] = React.useState([]);

  React.useEffect(() => {
    console.log("map", props.mapName);
    if (props.mapName) {
      if (parseInt(props.mapName)) {
        let year = parseInt(props.mapName);
        setTracks(
          db2.getCollection('tracks').chain()
            .find({trackSavedYear: year})
            .simplesort('added_at')
            .data()
            .reverse());
      } else if (props.mapName == 'all') {
        setTracks(
          db2.getCollection('tracks').chain()
            .simplesort('added_at')
            .data()
            .reverse());
      } else {
        setTracks([]);
      }
    }
  }, [props.mapName, props.savedTracksOffset]);

  const search = props.search.toLowerCase();

  function mapTrackId(t) {
    return '' + props.mapName + '-' + t.id;
  }

  function isOnMap(t) {
    return db2.getCollection('maps').findOne({
      mapTrackId: mapTrackId(t)
    });
  }

  function className(t) {
    let result = [];
    if (props.search.length > 2 && !matchSearch(t)) result.push("nomatch");
    if (isOnMap(t)) result.push("selected");
    return result.join(' ');
  }

  function matchSearch(t) {
    if (t.name.toLowerCase().includes(search)) return true;
    if (t.artist.toLowerCase().includes(search)) return true;
    if (t.album.toLowerCase().includes(search)) return true;
    return false;
  }

  function hover(t) {
    props.setPlayTrack(t);
  }

  function leave(t) {
    props.setPlayTrack(null);
  }

  return (
    <div className="savedTracks">
      <div>
        <div className="left">
          <h3>Liked Songs<span>by date added</span></h3>
        </div>
        <div className="right">
          <img className="icon reload" src={imgReload} onClick={props.loadSavedTracks} />
          <img className="icon deselectAll" src={imgX} onClick={() => deselectAll()} />
        </div>
      </div>
      {tracks?.length > 0 ? (
          <div className="scroller">
          {tracks?.map(t => (
            <img
              src={t.image ? t.image.url : ''}
              height={16} width={16}
              key={t.id}
              className={className(t)}
              title={t.artist + ": " + t.name}
              onMouseEnter={e => hover(t)}
              onMouseLeave={e => leave(t)}
              onClick={e => props.play(t)}
            />
          ))}
          </div>
      ) : (
        <div className="info">
            No saved tracks loaded yet.<br />
            {!props.token ? (
              <><a href={loginUrl}>Login to Spotify</a> to start.</>
            ) : (
              <a onClick={props.loadSavedTracks}>Import from Spotify</a>
            )}
          </div>
      )}
    </div>
  );
};

export default TrackLoader;
