function trackId(trackUri) {
  return trackUri.split(':')[2];
}

export function trackData(a, t, added_at) {
  return {
    name: t.name,
    album: a.album.name,
    artist: a.album.artists.length > 0 ? a.album.artists[0].name : '(undefined)',
    //  'genre': genre,
    uri: t.uri,
    id: trackId(t.uri),
    added_at: added_at,
    preview_url: t.preview_url,
    href: t.external_urls.spotify,
    release_year: parseInt(a.album.release_date.slice(0,4)),
    hasFeatures: 0, // initial value: no features loaded yet
    x: null,
    y: null,
    selected: 0,
    playlistIds: [],
    albumId: a.album.id,
    // track_liked: track_liked,
  }
}
