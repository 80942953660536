const redirect_url = process.env.REACT_APP_SPOTIFY_REDIRECT_URL;
const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;

const scopes = [
  "playlist-read-private",
  "user-library-read",
  "user-read-currently-playing",
  "user-read-recently-played",
  "user-read-playback-state",
  "user-modify-playback-state",
];

export const getTokenFromUrl = () => {
  let params = window.location.hash
    .substring(1)
    .split("&")
    .reduce((initial, item) => {
      let parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
  if ("access_token" in params) {
    return params.access_token;
  } else {
    return null;
  }
};

export const authEndpoint = "https://accounts.spotify.com/authorize";

export const loginUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirect_url}&scope=${scopes.join("%20")}&response_type=token`;
