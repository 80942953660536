import { UMAP } from 'umap-js';


export var runCounter = 0;
export var finishedCounter = 0;

export function incRunCounter() {
  runCounter += 1;  
}

var lastRunCounter = -1;

export async function calcMap(tracks, setEpoch, minDist, spread, dotSize) {
  console.log("CALC MAP", runCounter, tracks.length);
  setEpoch(0); // this triggers quick render mode
  if (runCounter <= lastRunCounter) {
    console.log('(retriggered with old run counter)', runCounter);
    // i'm not sure why calcMap is sometimes called repeatedly with the
    // same runCounter but different track counts
    //
    // return true; // must have been a redundant call. avoid retriggering.
  } else {
    lastRunCounter = runCounter;
  }
  if (!tracks || tracks.length == 0) {
    console.log("tracks", tracks);
    return true;
  }
  const len = tracks.length;
  console.log("len", len);
  if (len == 1) {
    let t = tracks[0];
    t.x = 0;
    t.y = 0;
    updateDotXY(t.id, 0, 0, dotSize);
    t.is_mapped = 1;
  } else {
    const config = ({
      nComponents: 2,
      minDist: minDist,
      spread: spread,
      nNeighbors: Math.min(15, len - 5),
      nEpochs: Math.round(Math.min(500, Math.max(50, len / 4)))
    })

    const umap = new UMAP(config);
    const trackIds = tracks.map(t => t.id);
    const dims = tracks.map(t => [
      t.energy,
      t.instrumentalness,
      t.danceability,
      t.valence,
      t.acousticness,
      t.liveness,
      t.speechiness,
      t.normalized_tempo
    ]);
    if (dims.some(ary => ary.includes(undefined))) {
      throw new Error("Some tracks don't have features loaded, which is a problem for the mapping algorithm. Make sure to filter them out before calling calcMap.");
    }
    let minEpoch = Math.round(config.nEpochs * 0.1);
    console.log("calculate embedding…");
    const embedding = await umap.fitAsync(dims, epochNumber => {
      if (tracks.length !== umap.embedding.length) {
        console.log("MAP: ABORT", tracks.length, umap.embedding.length);
        return false;
      }
      if ((epochNumber > minEpoch) && (epochNumber % 4 == 0)) {
        // console.log('epoch', epochNumber, config.nEpochs);
        umap.embedding.forEach(function(emb, i) {
          // console.log('i', i, 'x', tracks[i].x, 'y', tracks[i].y);
          tracks[i].x = emb[0];
          tracks[i].y = emb[1];
          updateDotXY(tracks[i].id, emb[0], emb[1], dotSize);
        });
        setEpoch(epochNumber);
      }
    });
    if (tracks.length !== umap.embedding.length) {
      console.log("MAP: RETURN", tracks.length, umap.embedding.length);
      return false;
    }
    console.log("update x,y positions for", embedding.length, " tracks…");
    embedding.forEach(function(emb, i) {
      tracks[i].x = emb[0];
      tracks[i].y = emb[1];
      tracks[i].is_mapped = 1;
    });
  }

  setEpoch(-1);
  finishedCounter = runCounter;
  return true;
}

export function updateDotXY(id, x, y, dotSize) {
  _updateDotXY(0, id, x, y, dotSize);
  _updateDotXY(1, id, x, y, dotSize);
}

function _updateDotXY(n, id, x, y, dotSize) {
  let dot = document.getElementById('dot-' + n + '-' + id);
  // console.log(n, dot);
  if (dot) {
    dot.cx.baseVal.value = x;
    dot.cy.baseVal.value = y;
    if (x && y && dotSize) {
      dot.r.baseVal.value = dotSize;
    }
  }  
}