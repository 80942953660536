
const zip = (a, b) => a.map((k, i) => [k, b[i]]);

function normalize_Tempo(t) {
  return ((t.tempo - 50) / 70) * t.danceability;
}

let spotifyApi = null;

export function setSpotifyApi(sapi) {
  spotifyApi = sapi;
}

export async function loadFeatures(key, tracks) {
  if (tracks && tracks.length > 0) {
    let features = await requestFeatures(key, tracks);
    zip(features, tracks).forEach(z => {
      let [f, t] = z;
      // console.log('zip', f, t);
      if (f) {
        // console.log(f.id, t.id);
        Object.assign(t, f);
        t.normalized_tempo = normalize_Tempo(f);
        t.hasFeatures = 1;
        // console.log("updated", t);
      }
    });
  } else {
    console.log("no more tracks found to load features for", tracks);
  }
}

var requests = [];

async function requestFeatures(key, tracks) {
  return new Promise((resolve, reject) => {
    // console.log('loadFeatures', key, tracks);
    requests.push({key: key, tracks: tracks, resolve: resolve, reject: reject});
  })
}

function fetch100() {
  let result = [];
  let count = 0;
  while (requests.length > 0 && ((count + requests[0].tracks.length) <= 100)) {
    let inc = requests.shift();
    result.push(inc);
    count += inc.tracks.length;
  };
  return result;
}

const interval = 200;

function requestLoop() {
  if (requests.length > 0) {
    // console.log('requests', requests);
    let requestPackage = fetch100();
    let tracks = requestPackage.reduce((acc, req) => acc.concat(req.tracks), []);
    let trackIds = tracks.map(t => t.id);
    // console.log('getAudioFeaturesForTracks', trackIds);
    spotifyApi.getAudioFeaturesForTracks(trackIds,
      async function(err, data) {
        if (err) {
          requestPackage.forEach(req => req.reject(err));
        } else if (data) {
          const features = data.audio_features
          // console.log("features:", features);
          let index = 0;
          requestPackage.forEach(req => {
            let count = req.tracks.length;
            req.resolve(features.slice(index, index + count));
            index += count;
          });
          setTimeout(requestLoop, interval);
        }
      }
    );
  } else {
    setTimeout(requestLoop, interval); //  set lower
  }
}

requestLoop();
