// import Dexie from 'dexie';


// export const db = new Dexie('spotimap');
// db.version(33).stores({
//   tracks: '++id, &uri, added_at, x, y, is_mapped, preview_url, name, artist, album, release_year, hasFeatures, selected, *playlistIds, albumId, saved, trackSavedYear',
//   playlists: '++id, index, selected, offset, *trackIds',
//   albums: '++id, added_at, index, selected, offset, *trackIds, albumSavedYear',
//   maps: '++id, &name', // name can be used for year numbers, among other things
// }).upgrade(tx => {
//   // db.delete(); // loading features is too expensive to delete everything
// });

export function mapped(tracks) {
  return tracks ? tracks.filter(mapped1) : [];
}

export function unmapped(tracks) {
  return tracks ? tracks.filter(unmapped1) : [];
}

export function mappable(tracks) {
  return tracks ? tracks.where('hasFeatures').equals(1).filter(t => t.selected == 1 && t.preview_url != null) : [];
}

export function loading(tracks) {
  return tracks ? tracks.where('hasFeatures').equals(0).filter(t => t.preview_url != null) : [];
}

export function mapped1(t) {
  return t.is_mapped == 1;
}

export function unmapped1(t) {
  return !mapped1(t);
}
